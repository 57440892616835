import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import S from 'string';
import { ReactComponent as APBCLogo } from './apbc.svg';
import Header from './components/layout/Header.js';
import CreativePage from "./components/pages/CreativePage";
import EventPage from "./components/pages/EventPage";
import Front from './components/pages/Front.js';
import NewsPage from "./components/pages/NewsPage";
import Page from './components/pages/Page.js';
import PageNotFound from "./components/pages/PageNotFound";
import StoryPage from "./components/pages/StoryPage";


library.add(far, fab);
Moment.globalFormat = 'DD.MM.YYYY';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      project: [],
      subtitle: '',
      menu: '',
      content: []
    };
  }

  componentDidMount() {
    this.fetchProject();
  }

  fetchProject = () => {
    const api = process.env.REACT_APP_API_URL + '/jsonapi/project/project/' +
      process.env.REACT_APP_PROJECT_ID + '?include=field_featured_image,field_logo&fields[file--file]=uri,url';
    fetch(api, {
      method: 'GET',
    })
      .then((resp) => {
        return resp.json();

      })
      .then((project) => {
        console.log(project)
        const logo = project.included ? project.included[1].attributes.uri.url : '';
        const featuredImage = project.included ? project.included[0].relationships.field_media_image : '';
        this.setState({
          'projectid': project.data.id,
          'project': project.data.attributes,
          'logo': logo,
          'menu': project.data.attributes.menu,
          'content': project,
          'featuredImage': featuredImage && featuredImage.data ? featuredImage.data : '',
          'externalLink': project.data.attributes.field_external && project.data.attributes.field_external,
          'imageLink': project.data.attributes.field_image_link &&  project.data.attributes.field_image_link.uri,
          'subtitle': project.data.attributes.field_subtitle
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }
  _renderSocials = () => {
    const socials = this.state.project.field_socials;
    if (socials && socials.length) {
      return socials.map((item, key) => {

        return (

          <a key={key} href={item.url} target="_blank" className="d-inline-block px-2" rel="noopener noreferrer">
            <FontAwesomeIcon icon={['fab', S(item.title).camelize().s.toLowerCase()]} /> {item.title}
          </a>

        )

      });
    } else {
      return false;
    }
  };





  render = () => {
    return (
      <>
        {this.state &&
          <Router>
            <div className="App">
              <Helmet>
                <title>{this.state.project.name}</title>
                <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
                <meta property="og:title" content={this.state.name} />
                <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
                <meta property="og:image" content={process.env.REACT_APP_IMAGE} />
                <meta property="og:type" content="website" />
              </Helmet>
              <div className="bg-light p-3">
                <div className="container">
                  <div className="row justify-content-center">
                    <a href="https://www.apbc.be" target="_blank" rel="noopener noreferrer"><APBCLogo
                      height="50" /></a>
                  </div>
                </div>
              </div>
              {this.state.project.menu &&
                <Header logo={this.state.logo} menu={this.state.menu} featuredImage={this.state.featuredImage}
                  imageLink={this.state.imageLink}  external={this.state.externalLink}
                  subtitle={this.state.subtitle} projectid={this.state.projectid}
                />
              }
              <Switch>
                <Route exact path="/"
                  component={() => <Front project={this.state.project} content={this.state.content} />} />
                <Route exact path="/404" component={() => <PageNotFound project={this.state.project} />} />
                <Route path="/stories/:id" component={() => <StoryPage project={this.state.project} />} />
                <Route path="/news/:id" component={() => <NewsPage project={this.state.project} />} />
                <Route path="/calendar/:id" component={() => <EventPage project={this.state.project} />} />
                <Route path="/creatives/:id"
                  component={() => <CreativePage project={this.state.project} />} />
                <Route path="/:uri" component={() => <Page project={this.state.project} />} />
                <Route component={() => <PageNotFound project={this.state.project} />} />
              </Switch>

              <footer className="bg-dark text-white">
                <div className="container py-5">
                  <div className="row">
                    <div className="col-auto">
                      {this.state && this.state.project.field_footer &&
                        <div className='footer-content'
                          dangerouslySetInnerHTML={{ __html: this.state.project.field_footer.value }}></div>
                      }
                    </div>
                    <div className="col-auto ml-auto">
                      {/*{this.state.content && this._renderSocials()}*/}

                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </Router>
        }
      </>
    );
  }
}

export default App;
