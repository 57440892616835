import React from 'react';
import Loader from "./../layout/Loader";
import Paragraph from "./Paragraph";

class ParagraphEmbed extends Paragraph {

    render = () => {
        return (
            <Loader loaded={this.state.loaded}>
                <div className='paragraph paragraph-embed row justify-content-center mb-5'>

                    <div className={(this.props.nested) ? "col-lg-12" : "col-lg-8"}>
                        {this.state.content.attributes && this.state.content.attributes.field_title &&
                            <h2 className='pargraph--title mb-3'>{this.state.content.attributes.field_title}</h2>
                        }

                        {this.state.content.attributes && this.state.content.attributes.field_embed_code &&
                            <div className='pargraph--body'>
                                <div className='embed-responsive-16by9 embed-responsive'>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.content.attributes.field_embed_code }}></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Loader>
        );
    }
}

export default ParagraphEmbed;