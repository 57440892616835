import React from 'react';
import { renderImage } from "../helper";
import Loader from "../layout/Loader";
import Paragraph from "./Paragraph";
class ParagraphMediaImage extends Paragraph {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            content: '',
        }
    }

    componentDidMount() {
        this._fetchParagraph();
    }

    _fetchParagraph = () => {
        let apiPath = this.props.type.replace('--', '/') + '/' + this.props.id + '?include=field_image';
        const api = process.env.REACT_APP_API_URL + '/jsonapi/' + apiPath
        fetch(api, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then((resp) => {
            return resp.json();
        })
            .then((paragraph) => {
                this._parseParagraph(paragraph)
            })
            .catch((error) => {
                console.log(error);
            });
    }




    _parseParagraph = (paragraph) => {
        const content = paragraph;
        this.setState({
            content: content.data,
            image: content.included[0].relationships.field_media_image.data.meta,
            loaded: true,
        });
    }

    render = () => {
        return (
            <Loader loaded={this.state.loaded}>
                <div className='paragraph paragraph--image row justify-content-center mb-5'>
                    <div className={this.props.nested ? 'col-12' : 'col-lg-8'}>
                        {this.state.content && this.state.content.attributes.field_title &&
                            <h2 className='pargraph--title'>{this.state.content.attributes.field_title}</h2>
                        }

                        {this.state.content.relationships && this.state.content.relationships.field_image &&
                            <>
                                {renderImage('large_landscape', this.state.image)}
                            </>
                        }
                    </div>
                </div>
            </Loader>
        );
    }
}

export default ParagraphMediaImage;
