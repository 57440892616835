import ClassNames from "classnames";
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Loader from "./Loader";
import Menu from "./Menu";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: (this.props.featuredImage.meta.imageDerivatives) ? this.props.featuredImage.meta.imageDerivatives.links.large.href : '',
            alt: (this.props.featuredImage.meta) ? this.props.featuredImage.meta.alt : '',
            imageLink: this.props.imageLink,
            loaded: false,
        }
    }



    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps !== this.props) {
            this.setState({
                loaded: true
            })
        }
    }

    render = () => {
      const headerClasses = ClassNames({
            header: true,
            'mb-5': this.props.location.pathname === '/',
            front: this.props.location.pathname === '/',
            'with-image': (this.props.featuredImage) ? true : false
        })
        return (
            <header className={headerClasses}>
                <Menu logo={this.props.logo} menuName={this.props.menu}></Menu>
                {this.props.featuredImage &&
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="offset-lg-4 col-lg-8">
                                {this.props.location.pathname === '/' &&
                                    <Loader loaded={true} delay={500}>
                                          {this.props.imageLink ?
                                          <a href={this.props.imageLink}  target={this.props.external && '_blank'}>
                                            <div className="row no-gutters">
                                            <div className="col-md-6">
                                                <div className="square">
                                                    <div className="inner">
                                                        <img src={this.state.image} alt={this.state.alt} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="square bg-secondary">
                                                    <div className="inner p-3">
                                                        <h2 className="text-primary">{this.props.subtitle}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                          </a>
                                          :
                                            <div className="row no-gutters">
                                            <div className="col-md-6">
                                              <div className="square">
                                                <div className="inner">
                                                  <img src={this.state.image} alt={this.state.alt} />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                            <div className="square bg-secondary">
                                            <div className="inner p-3">
                                            <h2 className="text-primary">{this.props.subtitle}</h2>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                          }

                                    </Loader>
                                }
                            </div>
                        </div>
                    </div>
                }

            </header>);
    }
}

export default withRouter(Header);
