import React, { Component } from 'react';
import RelatedContent from "../field/RelatedContent";
import Paragraph from "../paragraphs/Paragraph";
import ParagraphBanners from "../paragraphs/ParagraphBanners";
import ParagraphButton from "../paragraphs/ParagraphButton";
import ParagraphEmbed from "../paragraphs/ParagraphEmbed";
import ParagraphMediaImage from "../paragraphs/ParagraphMediaImage";
import ParagraphMediaImageGallery from "../paragraphs/ParagraphMediaImageGallery";
import ParagraphMultipleColumns from "../paragraphs/ParagraphMultipleColumns";
import ParagraphQuote from "../paragraphs/ParagraphQuote";
import ParagraphRelatedContent from "../paragraphs/ParagraphRelatedContent";

class Node extends Component {

    paragraphTypes = {
        'paragraph--media_image': ParagraphMediaImage,
        'paragraph--media_image_gallery': ParagraphMediaImageGallery,
        'paragraph--page_block_multiple_columns': ParagraphMultipleColumns,
        'paragraph--page_block_button': ParagraphButton,
        'paragraph--page_block_quote': ParagraphQuote,
        'paragraph--banners': ParagraphBanners,
        'paragraph--related_content': ParagraphRelatedContent,
        'paragraph--page_block_embed': ParagraphEmbed,
        'paragraph': Paragraph
    }

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }

    }

    componentDidMount() {
        this.setState({
            loaded: true
        })
    }



    _renderParagraphs(nested) {

        const paragraphs = this.props.content.relationships.field_page_blocks.data;
        if (paragraphs.length) {
            return paragraphs.map((item, key) => {
                let TagName = '';
                if (!(TagName = this.paragraphTypes[item.type])) {
                    TagName = this.paragraphTypes['paragraph'];
                }

                if (item.type !== "paragraph--page_block_horizontal_line") {
                    return <TagName
                        key={item.id}
                        id={item.id}
                        type={item.type}
                        nested={(nested === true) ? nested : false}
                    />
                } else {
                    return (
                        <div className="row justify-content-center my-3">
                            <div className="col-10">
                                <hr className="border-medium" />
                            </div>
                        </div>
                    )
                }

            });
        } else {
            return false;
        }
    }


    _renderRelatedContent(nested) {
        const relatedContent = this.props.content.relationships.field_related_content.data;
        if (relatedContent.length) {
            return <RelatedContent
                nodes={relatedContent}
                title={this.props.content.attributes.field_related_content_title}
            />
        } else {
            return false;
        }
    }



    render = () => {
        return (
            <article className="node">
                { this.props.content.relationships && this.props.content.relationships.field_page_blocks &&

                    <div className="paragraphs">
                        {this._renderParagraphs(this.props)}
                        {this.props.content.type === "node--page" &&
                            this._renderRelatedContent(this.props)
                        }
                    </div>

                }
            </article>
        )
    }

}

export default Node;
