import ClassNames from "classnames";
import React, { Component } from 'react';
import EventTeaser from "../nodes/EventTeaser";
import NewsTeaser from "../nodes/NewsTeaser";
import NodeTeaser from "../nodes/NodeTeaser";
import StoryTeaser from "../nodes/StoryTeaser";

class RelatedContent extends Component {

    nodeTypes = {
        'node--story': StoryTeaser,
        'node--news': NewsTeaser,
        'node--event': EventTeaser
    }

    constructor(props) {
        super(props);
        this.state = {
            content: {}
        };


    }



    componentDidMount() {
        this._fetchParagraph();
    }



    _fetchParagraph = () => {
        const urls = this.props.nodes.map(element => {
            return process.env.REACT_APP_API_URL + '/jsonapi/' + element.type.replace('--', '/') + '/' + element.id + '?include=field_media_image'
        });

        Promise.all(urls.map(url =>
            fetch(url)
        ))
            .then(responses => Promise.all(responses.map(r => r.json())))
            .then((paragraph) => {
                this._parseParagraph(paragraph);

            })

            .catch((error) => {
                console.log(error);
            });
    }




    _parseParagraph = (paragraph) => {
        this.setState({
            content: paragraph,
        });
    }


    _renderTeasers = () => {

        const teasers = this.state.content;

        if (teasers && teasers.length) {
            return teasers.map((item, key) => {
                let TagName = NodeTeaser;
                if (this.nodeTypes[item.data.type]) {
                    TagName = this.nodeTypes[item.data.type];
                }

                return (
                    <div className={this.props.nested ? 'col-md-6 col-lg-6' : 'col-md-6 col-lg-4'} key={key}>
                        <TagName
                            key={item.data.id}
                            nid={item.data.id}
                            title={item.data.attributes.title}
                            path={item.data.attributes.path}
                            bgColor={item.data.attributes.field_background_color ? item.data.attributes.field_background_color : 'primary_color'}
                            foreColor={item.data.attributes.field_text_color ? item.data.attributes.field_text_color : 'secondary_color'}
                            shape={item.data.attributes.field_shape}
                            text={(item.data.attributes.body) ? item.data.attributes.body.summary : ''}
                            image={(item.included[0].relationships.field_media_image.data) ? item.included[0].relationships.field_media_image.data : null}
                            attributes={item.data.attributes}
                            type={item.data.type}
                        />
                    </div>
                )

            });
        } else {
            return false;
        }
    }

    render = () => {

        const classes = ClassNames(
            this.props.className,
            {
                related: true,
            }
        )

        const titleClasses = ClassNames({
            'paragraph--title': true,
            [(!this.props.nested) ? 'offset-lg-2' : 'offset-0']: true,
            'mb-3': true,
        })

        return (
            <div className={classes}>
                {this.props.title &&
                    <h2 className={titleClasses}>{this.props.title}</h2>
                }
                <div className="row no-gutters">
                    {this.state.content && this._renderTeasers()}
                </div>
            </div>
        );
    }
}

export default RelatedContent;